import { Styles } from 'theme/interfaces';
import colors from 'theme/colors';

const responsiveBreakPointMd = 'md';
const responsiveBreakPointLg = 'lg';

const container: Styles = (theme) => ({
  overflow: 'hidden',
  marginTop: theme.spacing(2),
  marginX: theme.spacing(1),
  [theme.breakpoints.up(responsiveBreakPointMd)]: {
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.up(responsiveBreakPointLg)]: {
    maxWidth: '106.8rem',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

const barChartSectionsWrapper: Styles = {
  alignItems: 'center',
  justifyContent: 'center',
};

const headline: Styles = (theme) => ({
  textAlign:'justify',
  marginTop: '2rem',
  fontSize : '2rem',
  [theme.breakpoints.up(responsiveBreakPointMd)]: {
    marginTop: '2rem',
  },
});

const barChartSectionWrapper: Styles = (theme) => ({
  width: '100%',
  padding: theme.spacing(0),
  border: `0.1rem solid ${colors.GREY_BAR_CHART_TICK}`,
  borderRadius: '0.8rem',
  maxWidth: '99.6rem',
  marginTop: '4rem',
  [theme.breakpoints.up(responsiveBreakPointMd)]: {
    marginTop: '5.6rem',
    padding: '4rem',
  },
});

const barChartWrapper: Styles = (theme) => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '1.68rem',
  [theme.breakpoints.up(responsiveBreakPointMd)]: {
    marginTop: '3.2rem',
  },
});

const barChartSectionSubtitle: Styles = (theme) => ({
  marginTop: '1rem',
  fontFamily: 'SerialC',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  [theme.breakpoints.up(responsiveBreakPointMd)]: {
    fontSize: '1.8rem',
    lineHeight: '3.6rem',
  },
});

const barChartSectionDescription: Styles = (theme) => ({
  textAlign: 'center',
  fontFamily: 'SerialC',
  fontSize: '1.6rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  [theme.breakpoints.up(responsiveBreakPointMd)]: {
    lineHeight: '3.6rem',
  },
});

const barChartSectionDescriptionWrapper: Styles = (theme) => ({
  marginTop: '1.68rem',
  paddingY: '0.8rem',
  paddingX: '2.62rem',
  width: '100%',
  border: `0.0576rem solid ${colors.BLUE_BAR_CHART_GRADIENT_DARK}`,
  borderRadius: '0.4rem',
  [theme.breakpoints.up(responsiveBreakPointMd)]: {
    marginTop: '3.2rem',
  },
});

const styles = {
  container,
  barChartSectionsWrapper,
  headline,
  barChartWrapper,
  barChartSectionWrapper,
  barChartSectionSubtitle,
  barChartSectionDescription,
  barChartSectionDescriptionWrapper,
};

export default styles;
