import { Styles } from 'theme/interfaces';

const mobileResponsiveBreakpointLg = 'lg';
const mobileResponsiveBreakpointXl = 'xl';

const container: Styles = (theme) => ({
  marginTop: theme.spacing(2),
  marginX: theme.spacing(1),
  alignItems: 'center',
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    marginTop: theme.spacing(3),
    flexDirection: 'row',
    maxWidth: '144rem',
    paddingX: theme.spacing(0),
    marginX: 'auto',
  },
});

const contactWrapperMobile: Styles = (theme) => ({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingX: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderRadius: '1.6rem',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    display: 'none',
  },
});

const contactWrapperDesktop: Styles = (theme) => ({
  display: 'none',
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '0rem',
    borderRadius: '0.8rem',
    backgroundColor: theme.palette.primary.main,
  },
});

const title: Styles = (theme) => ({
  fontSize: '4.8rem',
  maxWidth: '27.3rem',
  textAlign: 'center',
  fontFamily: 'Dharma-Gothic-Rounded-E',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: 'normal',
  textTransform: 'uppercase',
  marginTop: '3.4rem',
  marginBottom: '4rem',
  color: theme.palette.common.black,
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    fontSize: '5.6rem',
    width: '50%',
    maxWidth: '73rem',
    marginTop: '0rem',
    marginBottom: '0rem',
    marginLeft: '3.2rem',
    marginRight: '20rem',
  },
  [theme.breakpoints.up(mobileResponsiveBreakpointXl)]: {
    width: '60%',
  },
});

const desktopButton: Styles = (theme) => ({
  paddingX: theme.spacing(1),
  '&:hover': {
   
    backgroundColor: '#d4d4d4',
    
  },
});

const styles = {
  container,
  contactWrapperMobile,
  contactWrapperDesktop,
  title,
  desktopButton,
};

export default styles;
