import { useEffect, useState } from 'react';
import Header from 'components/Header/Header';
import Calculator from 'components/Calculator/Calculator';
// import PromoContent from 'components/PromoContent/PromoContent';
import ImpactScore from 'components/ImpactScore/ImpactScore';
import PromoTiles from 'components/PromoTiles/PromoTiles';
import DetailEstimation from 'components/DetailEstimation/DetailEstimation';
import Contact from 'components/Contact/Contact';
import Footer from 'components/Footer/Footer';
import getConfig from 'utils/mocks/config';
import getHistoricParcels from 'utils/mocks/historicParcels';
import useGetEstimation from 'hooks/useGetEstimation';
import { ParcelType } from 'store/estimate/defaultState';
import './reset.css';

function App() {
  const [isEmailSent, setIsEmailSent] = useState(false);

const handleSendEmail = async (email:string) => {
   const url = 'https://hooks.zapier.com/hooks/catch/15209878/3nihofq/';
  try {
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
      mode: 'no-cors'
    });
    setIsEmailSent(true);
    
  } catch (error) {
  // eslint-disable-next-line no-console
    console.error('Error:', error);
  }
   
};   
  

  const { config } = getConfig();
  const defaultParcelType = getHistoricParcels().historicParcels[0]
    .type as ParcelType;

  const { setOrderQuantity, setParcelType, setReplacementPercentage, setImpact } =
    useGetEstimation();

  const defaultUsageValue = Number(1.8)
  const mobiusQuentity = Math.round( ((Number(config.orderQuantity)/12) / defaultUsageValue)) + 1
  const mobiuspackFixedCarbon = Number(0.49436)
  const mobiuspackVarCarbon = Number(0.16030)
  const mobiuspackWaste = Number(0.005)
  const mobiuspackWater = Number(6.18)
  // const mobiuspackScore = Number(71.36)

  let selectedParcelCarbonValue = Number(0)
  let selectedParcelWaterValue = Number(0)
  let selectedParcelWasteValue = Number(0)

  getHistoricParcels().historicParcels.map((parcel) => {
    if(parcel.type === defaultParcelType) {
      selectedParcelCarbonValue = parcel.carbon 
      selectedParcelWaterValue = parcel.water
      selectedParcelWasteValue = parcel.waste
    }
    return parcel 
  })

  useEffect(() => {
    setParcelType(defaultParcelType);
    setOrderQuantity(config.orderQuantity);

    const computedImpact = {
      carbonValue : (mobiusQuentity * ( mobiuspackFixedCarbon + mobiuspackVarCarbon * defaultUsageValue )),
      carbonReduction: Math.round(((Number(config.orderQuantity)  * selectedParcelCarbonValue) - (mobiusQuentity * (mobiuspackFixedCarbon + mobiuspackVarCarbon * defaultUsageValue)))),
      carbonPercentageReduction : ((mobiusQuentity * (mobiuspackFixedCarbon + mobiuspackVarCarbon * defaultUsageValue)) / (Number(config.orderQuantity)  * selectedParcelCarbonValue) - 1) * 100,
      wasteValue : (mobiusQuentity * mobiuspackWaste),
      wasteReduction: Math.round((Number(config.orderQuantity)  * selectedParcelWasteValue) - (mobiusQuentity * mobiuspackWaste)),
      wastePercentageReduction : ((mobiusQuentity * mobiuspackWaste) / (Number(config.orderQuantity)  * selectedParcelWasteValue) - 1) * 100,
      waterValue : (mobiusQuentity * mobiuspackWater),
      waterReduction: Math.round((Number(config.orderQuantity)  * selectedParcelWaterValue) - (mobiusQuentity * mobiuspackWater)),
      waterPercentageReduction : ((mobiusQuentity * mobiuspackWater) / (Number(config.orderQuantity)  * selectedParcelWaterValue)  - 1) * 100,
      scoreValue : 10,
      scoreReduction: 100,
      scorePercentageReduction : 60,
    }

    setImpact( computedImpact )
    setReplacementPercentage(config.replacementPercentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <Calculator />
      <ImpactScore />
      <DetailEstimation
        isEmailSent={isEmailSent}
        handleSendEmail={handleSendEmail}
      />
     {/* <PromoContent /> */}
      <PromoTiles />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
