import { createTheme } from '@mui/material';
import DharmaGothicRoundedE from 'font/DharmaGothicRndE-Regular.ttf';
import SerialCThin from 'font/SerialC-Thin.ttf';
import SerialCLight from 'font/SerialC-Light.ttf';
import SerialCRegular from 'font/SerialC-Regular.ttf';
import SerialCMedium from 'font/SerialC-Medium.ttf';
import SerialCBold from 'font/SerialC-Bold.ttf';
import SerialCBlack from 'font/SerialC-Black.ttf';
import SerialCHeavy from 'font/SerialC-Heavy.ttf';

import colors from './colors';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      body {
        background-color: ${colors.BLACK};
      }

      @font-face {
          font-family: 'Dharma-Gothic-Rounded-E';
          src: url('${DharmaGothicRoundedE}') format('truetype');
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: 'SerialC';
          src: url('${SerialCThin}') format('truetype');
          font-weight: 100;
          font-style: normal;
        }

        @font-face {
          font-family: 'SerialC';
          src: url('${SerialCLight}') format('truetype');
          font-weight: 300;
          font-style: normal;
        }

        @font-face {
          font-family: 'SerialC';
          src: url(${SerialCRegular}) format('truetype');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: 'SerialC';
          src: url(${SerialCMedium}) format('truetype');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: 'SerialC';
          src: url(${SerialCBold}) format('truetype');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'SerialC';
          src: url(${SerialCBlack}) format('truetype');
          font-weight: 900;
          font-style: normal;
        }

        @font-face {
          font-family: 'SerialC';
          src: url(${SerialCHeavy}) format('truetype');
          font-weight: 950;
          font-style: normal;
        }
    `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.WHITE,
          fontStyle: 'normal',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.WHITE,
          borderRadius: '0.8rem',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: '1.6rem',
          paddingX: '2.4rem',
          boxSizing: 'border-box',
          height: '4.8rem',
          gap: '1.6rem',
          flexDirection: 'row',
          '&:hover': {
            backgroundColor: colors.WHITE,
          },
          '&:disabled': {
            backgroundColor: colors.WHITE_DISABLED,
            color: colors.GREY_DISABLED,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: '0.8rem',
          color: colors.WHITE,
          backgroundColor: colors.BLACK,
          '& .MuiInputBase-input': {
            fontSize: '1.6rem',
            lineHeight: '2.77rem',
            color: colors.WHITE,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.WHITE,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.WHITE,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.WHITE,
          },
          '& .MuiSelect-icon': {
            color: colors.WHITE,
            top: 'unset',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          borderColor: colors.WHITE,
          borderRadius: '0.8rem',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.WHITE}`,
            borderRadius: '0.8rem',
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${colors.WHITE}`,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: colors.WHITE,
            },
          '& .MuiInputLabel-root.Mui-focused': {
            color: colors.WHITE,
          },
          '& input': {
            color: colors.WHITE,
            fontSize : '1.6rem',
            lineHeight: '2.77rem',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '& ul': {
            backgroundColor: colors.BLACK,
            border: `0.1rem solid ${colors.WHITE}`,
            borderRadius: '0.8rem',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: colors.BLACK,
          color: colors.WHITE,
          fontSize: '1.6rem',
          lineHeight: '2.4rem',
          '@media screen and (min-width: 768px)': {
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
          },
          '&:hover': {
            backgroundColor: colors.BLUE_MAIN,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.WHITE,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    }
  },
  typography: {
    h1: {
      fontFamily: 'Dharma-Gothic-Rounded-E',
      fontWeight: 800,
      fontStyle: 'normal',
      fontSize: '5.8rem',
      lineHeight: '5.8rem',
      textTransform: 'uppercase',
      '@media screen and (min-width: 768px)': {
        fontSize: '7.2rem',
        lineHeight: '7.2rem',
      },
    },
    h2: {
      fontFamily: 'Dharma-Gothic-Rounded-E',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '4.8rem',
      lineHeight: '4.8rem',
      '@media screen and (min-width: 768px)': {
        fontSize: '7.2rem',
        lineHeight: '7.2rem',
      },
    },
    h3: {
      fontFamily: 'Dharma-Gothic-Rounded-E',
      fontWeight: 800,
      fontStyle: 'normal',
      fontSize: '5.4rem',
      lineHeight: '5.4rem',
      '@media screen and (max-width: 1000px)': {
        margin: '1rem',
      },
      '@media screen and (min-width: 768px)': {
        fontSize: '6.4rem',
        lineHeight: '6.4rem',
      },
    },
    h4: {
      fontFamily: 'Dharma-Gothic-Rounded-E',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '2.4rem',
      lineHeight: '2.4rem',
      
      '@media screen and (min-width: 768px)': {
        fontSize: '4rem',
        lineHeight: '4rem',
      },
    },
    body1: {
      fontFamily: 'SerialC',
      fontWeight: 700,
      fontStyle: 'normal',
      lineHeight: '3.36rem',
      
    },
    nav: {
      fontFamily: 'SerialC',
      fontWeight: 950,
      fontStyle: 'normal',
      fontSize: '2rem',
      lineHeight: 'normal',
      letterSpacing: '-0.025rem',
      textShadow: `0rem 0rem 0.08rem ${colors.WHITE}`
    },
    footer: {
      fontFamily: 'SerialC',
      fontStyle: 'normal',
      fontWeight: 100,
      fontSize: '1.6rem',
      letterSpacing: '0rem',
      textShadow: `0rem 0rem 0.1rem ${colors.WHITE}`
    },
    button: {
      fontFamily: 'SerialC',
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: 950,
      lineHeight: 'normal',
      textTransform: 'uppercase',
      letterSpacing: '0.16rem',
      paddingTop:'0.5rem',
      paddingBottom:'0.5rem',
      marginTop:'2rem',
      marginBottom:'2rem',
    },
    caption: {
      fontFamily: 'SerialC',
      fontSize: '1.6rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      padding: '0rem',
      '@media screen and (min-width: 768px)': {
        fontSize: '2rem',
        lineHeight: '3rem',
      },
    },
  },
  spacing: ['1.6rem', '2.4rem', '7.2rem', '14.4rem'],
  palette: {
    common: {
      black: colors.BLACK,
      white: colors.WHITE,
    },
    primary: {
      main: colors.BLUE_MAIN,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
});

export default theme;
