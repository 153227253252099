import { Typography, Stack } from '@mui/material'; // Button
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
// import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import useGetEstimation from 'hooks/useGetEstimation';

import ScoreBoxV2 from './ScoreBoxes/ScoreBox-v2';
// import ImpactSlider from './ImpactSlider/ImpactSlider';
import styles from './styles';
import { BoxType, EstimationDetailsV2 } from './interfaces';

export default function ImpactScore() {
  const { t } = useTranslation();

  // replacementPercentage, setReplacementPercentage, handleReset
  const { impact, orderQuantity, parcelType } =
    useGetEstimation();

  // const handleChangeSlider = (e: Event) => {
  //   const inputElement = e.target as HTMLInputElement;
  //   if (!inputElement) return;

  //   const { value } = inputElement;
  //   setReplacementPercentage(String(value));
  // };

  // const handleOnReset = () => {
  //   handleReset();
  // };


  const defaultUsageValue = Number(1.8)
  const mobiusQuentity = Math.round( ((Number(orderQuantity)/12) / defaultUsageValue)) + 1 
  
  const estimationParcels = {
    name: 'parcel',
    value: mobiusQuentity,
  }
  const estimationCarbon = {
    name: 'carbon',
    value: impact.carbonReduction,
  }
    const estimationWaste = {
    name: 'waste',
    value: impact.wasteReduction,
  }
  // Other possibilities of estimation details
  /*
  const estimationWater = {
    name: "water",
    value: impact.waterReduction,
  }
  const estimationScore = {
    name: "score",
    value: impact.scoreReduction,
  } */ 



  return (
    <Element name="impact-score">
      <Stack sx={styles.container}>
        <Stack sx={styles.mainSideWrapper} bgcolor="primary.main">
          <Typography variant="h3">{t('impactScore.title_main')}</Typography>
          { /* Result for sizing flotte */ }
          <Stack sx={styles.titlesWrapper}>
            <Typography variant="body1" sx={styles.mainSubTitle}>
              {/* MOBIUSpack réduirait votre impact estimé, en remplaçant votre flotte de { orderQuantity} emballages en { parcelType === undefined ? '' : parcelType.toLocaleLowerCase() } */}
              En remplaçant votre flotte, de { orderQuantity } emballages jetables en { parcelType === undefined ? '' : parcelType.toLocaleLowerCase() }, MOBIUSpack améliore votre impact de :
             { /* Remplacer votre flotte de { orderQuantity} emballages en { parcelType === undefined ? '' : parcelType.toLocaleLowerCase() } */ } { /* } { mobiusQuentity } emballage{ mobiusQuentity > 1 ? 's ' : ' '} 
              réemployable{ mobiusQuentity > 1 ? 's ' : ' '}MOBIUSpack reduirait votre impact estimé de: */ } 
            </Typography>
            <Stack sx={styles.rowContainer}>

              <ScoreBoxV2
                type={BoxType.main}
                estimation={estimationCarbon as EstimationDetailsV2}
                caption = "de réduction d'impact carbone estimée"
                style={{gap: '1.2rem'}}
              />
              <ScoreBoxV2
              type={BoxType.main}
              estimation={estimationWaste as EstimationDetailsV2}
              caption = "de réduction de déchets estimé"
              style={{gap: '1.2rem'}}
              />
              <ScoreBoxV2  
                type={BoxType.main}
                estimation={estimationParcels as EstimationDetailsV2}
                caption = " "
                style={{gap: '3.2rem',paddingTop:'5.6rem'}}
              />
            </Stack>

          </Stack>
           
          {/* <ImpactSlider
            replacementPercentage={replacementPercentage}
            handleChangeSlider={handleChangeSlider}
          /> */}
           { /* Result about the environment impact */ }
          
        </Stack>

      </Stack>
    </Element>
  );
}
