import React, { useEffect, useRef, memo, useState } from 'react';
import { Box } from '@mui/material';
import * as d3 from 'd3';
import colors from 'theme/colors';

import PollutionType from '../enums';

import { createGradient, styleXAxis, styleYAxis } from './utils';
import './override.css';
import {
  CHART_BAR_RADIUS,
  CHART_HEIGHT_DESKTOP,
  CHART_HEIGHT_MOBILE,
  mobileResponsiveBreakpointMd,
  mobileResponsiveBreakpointSm,
  TOOLTIP_OFFSET,
  Y_AXIS_TICKS_LINE_OFFSET,
  Y_AXIS_TICKS_TEXT_OFFSET,
  TOOLTIP_HEIGHT,
} from './dimensions';
import styles from './styles';

type Pollution = {
  type: PollutionType;
  value: number;
  dimension : string;
};

interface DetailEstimationBarChartProps {
  data: Pollution[];
  type: PollutionType;
}

function DetailEstimationBarChartComponent({
  data,
  type,
}: DetailEstimationBarChartProps) {
  const svgRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(
    window.innerWidth > mobileResponsiveBreakpointMd
      ? CHART_HEIGHT_DESKTOP
      : CHART_HEIGHT_MOBILE,
  );
    
  useEffect(() => {
    if (!containerRef?.current?.clientWidth) return;
    setWidth(containerRef.current.clientWidth);
  }, [containerRef]);

  useEffect(() => {
    const handleResize = () => {
      setHeight(
        window.innerWidth > mobileResponsiveBreakpointMd
          ? CHART_HEIGHT_DESKTOP
          : CHART_HEIGHT_MOBILE,
      );

      if (!containerRef?.current?.clientWidth) return;
      setWidth(containerRef.current.clientWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!data || !containerRef?.current?.clientWidth) return;

    // Create SVG container
    const svg = d3.select(svgRef.current);
    // clear before new render
    svg.selectAll('*').remove();

    // Create x-axis
    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.type))
      .range([Y_AXIS_TICKS_TEXT_OFFSET, width+10])
      .padding(width > mobileResponsiveBreakpointMd ? 0.6 : 0.4);

    const xAxisGroup = svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(xScale));

    styleXAxis(xAxisGroup);

    // Create y-axis
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value * 1.2) || 0])
      .range([height, 0]);

    const yAxisGroup = svg.append('g').attr('transform', 'translate(-13, 0)').call(
      d3
        .axisLeft(yScale)
        .ticks(7)
        .tickSizeInner(-width + Y_AXIS_TICKS_LINE_OFFSET),
    );

    styleYAxis(yAxisGroup);

    createGradient(svg);

    // Create bars
    svg
      .selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.type) || 0)
      .attr('y', (d) => yScale(d.value))
      .attr('width', xScale.bandwidth())
      .attr('height', (d) => height - yScale(d.value))
      .attr('fill', 'url(#myGradient)')
      .attr('rx', CHART_BAR_RADIUS)
      .attr('ry', CHART_BAR_RADIUS)
      .on('mouseover', (_event, d) => {
        const tooltip = d3.select(`#barchart-tooltip-${type}`);
        const barY = yScale(d.value);
        const barX = xScale(d.type) || 0;
        const leftOffset =
          barX +
          (width > mobileResponsiveBreakpointSm
            ? TOOLTIP_OFFSET
            : TOOLTIP_OFFSET / 2);
        const topOffset = barY - TOOLTIP_OFFSET;

        tooltip.style('opacity', 0.9);
        tooltip
          .html(`<strong>${d.value}</strong>${d.dimension}`)
          .style('left', `${leftOffset}px`)
          .style('top', `${topOffset}px`);
        svg.style('cursor', 'pointer');

        // line between bar and tooltip
        svg
          .append('line')
          .attr('id', 'barchart-tooltip-line')
          .attr('x1', barX + xScale.bandwidth() / 2)
          .attr('y1', barY)
          .attr('x2', leftOffset)
          .attr('y2', topOffset + TOOLTIP_HEIGHT)
          .attr('stroke', colors.WHITE)
          .attr('stroke-width', 1);

        // line circle
        svg
          .append('circle')
          .attr('id', 'barchart-tooltip-line-circle')
          .attr('cx', barX + xScale.bandwidth() / 2)
          .attr('cy', barY)
          .attr('r', 3)
          .attr('fill', colors.WHITE);
      })
      .on('mouseout', () => {
        const tooltip = d3.select(`#barchart-tooltip-${type}`);
        tooltip.style('opacity', 0);
        svg.style('cursor', 'auto');
        svg.select('#barchart-tooltip-line').remove();
        svg.select('#barchart-tooltip-line-circle').remove();
      });
  }, [data, height, type, width]);

  return (
    <Box sx={styles.container} ref={containerRef}>
      <svg ref={svgRef} width="100%" height={height + TOOLTIP_OFFSET} />
      <Box id={`barchart-tooltip-${type}`} sx={styles.tooltip} />
    </Box>
  );
}

const DetailEstimationBarChart = memo(DetailEstimationBarChartComponent);
export default DetailEstimationBarChart;
