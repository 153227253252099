export const mobileResponsiveBreakpointSm = 512;
export const mobileResponsiveBreakpointMd = 768;
export const CHART_BAR_MAX_WIDTH = 896;
export const CHART_HEIGHT_MOBILE = 288;
export const CHART_HEIGHT_DESKTOP = 421;
export const CHART_BAR_RADIUS = 10;
export const TOOLTIP_OFFSET = 50;
export const TOOLTIP_HEIGHT = 30;
export const Y_AXIS_TICKS_TEXT_OFFSET = 36;
export const Y_AXIS_TICKS_LINE_OFFSET = 46;
export const X_AXIS_TEXT_OFFSET = 10;
