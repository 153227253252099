import { createAction } from '@reduxjs/toolkit';

import { ParcelType } from './defaultState';

export const setParcelType = createAction<ParcelType>(
  'estimate/setParcelType'
);
export const setOrderQuantity = createAction<string>(
  'estimate/setOrderQuantity',
);
export const setReplacementPercentage = createAction<string>(
  'estimate/setReplacementPercentage',
);
export const setImpact = createAction<any>(
  'estimate/setImpact',
);