import { Styles } from 'theme/interfaces';

const mobileResponsiveBreakpoint = 'lg';

const firstContainer: Styles = (theme) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    marginTop: theme.spacing(3),
    height: '13.5rem',
    alignItems: 'flex-start',
  },
});

const secondContainer: Styles = (theme) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    marginBottom: theme.spacing(3),
    height: '17rem',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const imageWrapper: Styles = () => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const addressInfoWrapper: Styles = (theme) => ({
  alignItems: 'center',
  height: '100%',
  marginX: 'auto',
  gap: '1.6rem',
  marginTop: '6.4rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    marginTop: '0rem',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: '9.9rem',
  },
});

const logoText: Styles = () => ({
  lineHeight: 'normal',
});

const address: Styles = (theme) => ({
  lineHeight: '150%',
  textAlign: 'center',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    textAlign: 'left',
  },
});

const linksWrapper: Styles = (theme) => ({
  alignItems: 'center',
  gap: '1.4rem',
  marginTop: '2.4rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '0rem',
    marginLeft: '10.5rem',
  },
});

const link: Styles = (theme) => ({
  color: theme.palette.common.white,
  textDecoration: 'none',
  fontFamily: 'Dharma-Gothic-Rounded-E',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  fontSize: '2.686rem',
  
});

const copyrightInfoWrapper: Styles = (theme) => ({
  alignItems: 'center',
  marginTop: '6.2rem',
  marginX: 'auto',
  height: '100%',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    marginTop: '7.8rem',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});

const copyright: Styles = (theme) => ({
  lineHeight: '2.4rem',
  textAlign: 'center',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    textAlign: 'right',
    paddingRight: '8.8rem',
  },
});

const arrowBackToBasic: Styles = (theme) => ({
  width: 'auto',
  height: '10rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    marginLeft: '5rem',
    marginTop: '1.2rem',
  },
});

const styles = {
  firstContainer,
  secondContainer,
  link,
  imageWrapper,
  linksWrapper,
  logoText,
  address,
  addressInfoWrapper,
  copyrightInfoWrapper,
  copyright,
  arrowBackToBasic,
};

export default styles;
