import { Grid, Stack, Link, Typography, Box } from '@mui/material';
import Container from 'common/Container/Container';
import { useTranslation } from 'react-i18next';
import ArrowBackToBasic from 'assets/images/arrow_back_to_basic.png';

import styles from './styles';
import links from './links';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Container>
      <Grid container sx={styles.firstContainer}>
        <Grid item xs={12} lg={2}>
          <Stack sx={styles.imageWrapper}>
            <Box
              component="img"
              src={ArrowBackToBasic}
              sx={styles.arrowBackToBasic}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Stack sx={styles.addressInfoWrapper}>
            <Typography sx={styles.logoText} variant="footer">
              MOBIUSpacK
            </Typography>
            <Typography sx={styles.address} variant="footer">
              Le Village by CA
              <br />
              225, rue des templiers
              <br />
              59000 Lille
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Stack sx={styles.linksWrapper}>
            {links.map((link) => (
              <Link
                sx={styles.link}
                key={link.label}
                href={link.href}
                target={link.target}
              >
                {t(link.label)}
              </Link>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={styles.secondContainer}>
        <Grid item xs={12} lg={3}>
          <Stack sx={styles.copyrightInfoWrapper}>
            <Typography variant="footer" sx={styles.copyright}>
              {t('footer.copyright', { year: new Date().getFullYear() })}
              <br />
              {t('footer.all_rights_reserved')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
