import { useSelector, useDispatch } from 'react-redux';
import {
  getParcelType,
  getOrderQuantity,
  getReplacementPercentage,
  getImpact,
} from 'store/estimate/selectors';
import {
  setParcelType as setParcelTypeAction,
  setOrderQuantity as setOrderQuantityAction,
  setReplacementPercentage as setReplacementPercentageAction,
  setImpact as setImpactAction,
} from 'store/estimate/actions';
import { ParcelType, ComputedImpactType } from 'store/estimate/defaultState';

export default function useGetEstimation() {
  const dispatch = useDispatch();

  const parcelType = useSelector(getParcelType);
  const orderQuantity = useSelector(getOrderQuantity);
  const replacementPercentage = useSelector(getReplacementPercentage);
  const impact = useSelector(getImpact);


  const setParcelType = (parcelTypeValue: ParcelType) =>
    dispatch(setParcelTypeAction(parcelTypeValue));

  const setOrderQuantity = (orderQuantityValue: string) =>
    dispatch(setOrderQuantityAction(orderQuantityValue));

  const setReplacementPercentage = (replacementPercentageValue: string) =>
    dispatch(setReplacementPercentageAction(replacementPercentageValue));

  const setImpact = (impactValue : ComputedImpactType) => 
  dispatch(setImpactAction(impactValue));

  const handleReset = () => {
    setParcelType(ParcelType.carton);
    setOrderQuantity('100');
    setReplacementPercentage('7');
  };

  return {
    parcelType,
    setParcelType,
    orderQuantity,
    setOrderQuantity,
    replacementPercentage,
    setReplacementPercentage,
    setImpact,
    impact,
    handleReset,
  };
}
