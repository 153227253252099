import { Styles } from 'theme/interfaces';

const navbar: Styles = (theme) => ({
  backgroundColor: theme.palette.common.black,
  boxShadow: 'none',
  position: 'static',
  paddingTop: '0.8rem',
});

const styles = {
  navbar,
};

export default styles;
