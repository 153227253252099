/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  setParcelType,
  setOrderQuantity,
  setReplacementPercentage,
  setImpact,
} from './actions';
import defaultState from './defaultState';

const estimate = createReducer(defaultState, (builder) => {
  builder.addCase(setParcelType, (state, action) => {
    state.parcelType = action.payload;
  });
  builder.addCase(setOrderQuantity, (state, action) => {
    state.orderQuantity = action.payload;
  });
  builder.addCase(setReplacementPercentage, (state, action) => {
    state.replacementPercentage = action.payload;
  });
  builder.addCase(setImpact, (state, action) => {
    state.impact = action.payload;
  });
});

export default estimate;
