import { Typography, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectArrowIcon from 'assets/icons/SelectArrowIcon';
import getHistoricParcels from 'utils/mocks/historicParcels';
import { ParcelType } from 'store/estimate/defaultState';

import styles from './styles';



const MIN_ORDER_QUANTITY = 100;
const MAX_ORDER_QUANTITY = 100000000;

interface CalculatorInputsProps {
  onOrderQuantityChange: (value: string) => void;
  onParcelTypeChange: (value: ParcelType) => void;
  onClick: () => void;
  inputOrderQuantity:string;
  inputParcelType:string;
}

export default function CalculatorInputs({ 
  onOrderQuantityChange, 
  onParcelTypeChange ,
  onClick,
  inputOrderQuantity,
  inputParcelType,
  
}: CalculatorInputsProps) {

   
    

  const { historicParcels } = getHistoricParcels();
  const parcelTypes = historicParcels.map((parcel) => parcel);
  
  const { t } = useTranslation();

  if (!inputParcelType) return null; // TODO: replace it to loading spinner with real api

  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.inputWrapper}>
        <Typography variant="caption">
          {t('calculator.label_type_of_packaging')}
        </Typography>
        <Select
          IconComponent={SelectArrowIcon}
          value={inputParcelType}
          onChange={(e) => {
            onParcelTypeChange(e.target.value as any)
          }}
          sx={styles.select}
        >
          {parcelTypes.map((parcel) => (
            <MenuItem key={parcel.type} value={parcel.type}>
              {parcel.type}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Stack sx={styles.inputWrapper}>
        <Typography variant="caption">
          {t('calculator.label_quantity_of_orders')}
        </Typography>
        <TextField
          type="number"
          inputProps={{
            min: MIN_ORDER_QUANTITY,
            max: MAX_ORDER_QUANTITY,
            step: 1,
          }}
          sx={styles.input}
          value={inputOrderQuantity}
          onChange={(e) => 
            {onOrderQuantityChange(e.target.value);
              
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); 
                onClick();
              }
            }}
          
        />
      </Stack>

      {/* // TODO : CREATE AN INPUT TO SET THE CYCLE */}
      {/* 
      <Stack sx={styles.inputWrapper}>
        <Typography variant="caption">
          {t('calculator.label_quantity_of_orders')}
        </Typography>
        <TextField
          type="number"
          inputProps={{
            min: MIN_ORDER_QUANTITY,
            max: MAX_ORDER_QUANTITY,
            step: 1,
          }}
          sx={styles.input}
          value={orderQuantity}
          onChange={(e) => setOrderQuantity(e.target.value)}
        />
      </Stack> */}

    </Stack>
  );
}
