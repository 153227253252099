import { Styles } from 'theme/interfaces';

const mobileResponsiveBreakpoint = 'lg';

const container: Styles = (theme) => ({
  overflow: 'hidden',
  marginTop: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    marginTop: theme.spacing(3),
    maxWidth: '150rem',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

const styles = {
  container,
};

export default styles;
