import React from 'react';
import { createSvgIcon, Box, BoxProps } from '@mui/material';
import colors from 'theme/colors';

const SelectArrowIcon = createSvgIcon(
  <svg width="15" height="8" viewBox="0 0 15 8" fill="none">
    <path
      d="M1 1L6.82172 6.3739C7.20478 6.72749 7.79522 6.72749 8.17828 6.3739L14 1"
      stroke={colors.WHITE}
    />
  </svg>,
  'SelectArrowIcon',
);

function SelectArrowIconWithProps(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={{
        marginRight: '1.6rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SelectArrowIcon />
    </Box>
  );
}

export default SelectArrowIconWithProps;
