import * as React from 'react';
import { createSvgIcon } from '@mui/material';
import colors from 'theme/colors';

const ArrowRightIcon = createSvgIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_725_132)">
      <path
        d="M15.7443 7.37826L9.9661 1.59996C9.80116 1.43502 9.58132 1.34448 9.34692 1.34448C9.11225 1.34448 8.89255 1.43515 8.7276 1.59996L8.20299 2.1247C8.03818 2.28939 7.94738 2.50935 7.94738 2.74389C7.94738 2.97829 8.03818 3.20567 8.20299 3.37035L11.5739 6.74867H0.864383C0.381525 6.74867 0 7.12668 0 7.60967V8.35151C0 8.8345 0.381525 9.25063 0.864383 9.25063H11.6121L8.20312 12.6478C8.03831 12.8127 7.94751 13.0267 7.94751 13.2613C7.94751 13.4955 8.03831 13.7126 8.20312 13.8775L8.72773 14.4005C8.89268 14.5654 9.11238 14.6553 9.34705 14.6553C9.58145 14.6553 9.80129 14.5643 9.96623 14.3993L15.7444 8.62117C15.9097 8.45571 16.0007 8.23483 16 8.00004C16.0005 7.76446 15.9097 7.54346 15.7443 7.37826Z"
        fill={colors.BLACK}
      />
    </g>
    <defs>
      <clipPath id="clip0_725_132">
        <rect width="16" height="16" fill={colors.WHITE} />
      </clipPath>
    </defs>
  </svg>,
  'ArrowRightIcon',
);

export default ArrowRightIcon;
