export enum ParcelType {
  kraft = ' Kraft 50% recyclé',
  carton = 'Carton 100% recyclé',
  cartonV = 'Carton vierge',
  polybag = 'Polybag 60% recyclé',
}

export interface ComputedImpactType {
  carbonValue: number,
  carbonReduction : number,
  carbonPercentageReduction : number,
  wasteValue: number,
  wasteReduction : number,
  wastePercentageReduction : number,
  waterValue: number,
  waterReduction : number,
  waterPercentageReduction : number,
  scoreValue : number,
  scoreReduction : number,
  scorePercentageReduction: number,
}

export interface EstimateState {
  parcelType: ParcelType;
  orderQuantity: string;
  replacementPercentage: string;
  impact: ComputedImpactType;
}

const defaultState = {
  impact : {
    carbonValue: 0,
    carbonReduction: 0,
    carbonPercentageReduction: 0,
    wasteValue: 0,
    wasteReduction: 0,
    wastePercentageReduction: 0,
    waterValue: 0,
    waterReduction: 0,
    waterPercentageReduction: 0,
    scoreValue : 0,
    scoreReduction : 0,
    scorePercentageReduction: 0,
  }
} as EstimateState;

export default defaultState;
