import { Styles } from 'theme/interfaces';

const container: Styles = (theme) => ({
  paddingX: '0.75rem',
  marginX: 'auto',
  [theme.breakpoints.up('xs')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '54rem',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '72rem',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '96rem',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '114rem',
  },
  [theme.breakpoints.up('xxl')]: {
    maxWidth: '132rem',
  },
});

const styles = {
  container,
};

export default styles;
