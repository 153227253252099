import { Styles } from 'theme/interfaces';
import colors from 'theme/colors';

const mobileResponsiveBreakpointMd = 'md';
const mobileResponsiveBreakpointLg = 'lg';
const mobileResponsiveBreakpointSm = 'sm';

const logo: Styles = (theme) => ({
  
  height: '5rem',
  maxWidth: '100%',
  padding: '0.5rem 0',
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    
    height:'6.89rem',
  },
});

const headerWrapper: Styles = (theme) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'auto',
  paddingY: '0.3rem',
  marginX: '2rem',
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    marginX:'11.0rem',
    width : 'auto',
  },
});

const hamburgerIconWrapper: Styles = (theme) => ({
  boxShadow: `0rem 0rem 0rem 0.1rem ${colors.WHITE_HAMBURGER_BORDER}`,
  padding: '0.5rem 1.2rem',
  borderRadius: '0.6rem',
  outline: 'none',
  ':focus': {
    boxShadow: `0rem 0rem 0rem 0.4rem ${colors.WHITE_HAMBURGER_ICON}`,
  },
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    display: 'none',
  },
});

const mobileDisplayWrapper: Styles = (theme) => ({
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    display: 'none',
  },
});

const desktopDisplayWrapper: Styles = (theme) => ({
  [theme.breakpoints.down(mobileResponsiveBreakpointLg)]: {
    display: 'none',
  },
});

const headerListWrapper: Styles = (theme) => ({
  alignItems: 'flex-start',
  gap: '1rem',
  justifyContent: 'center',
  paddingLeft: '0.8rem',
  backgroundColor: theme.palette.common.black,
  [theme.breakpoints.up(mobileResponsiveBreakpointMd)]: {
    gap: '0rem',
  },
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '0rem',
    marginTop: '0',
    paddingRight: '1.4rem',
    paddingLeft: '0rem',
  },
});

const linkFirst: Styles = (theme) => ({
  paddingTop: '0.4rem',
  paddingLeft: '0.8rem',
  paddingRight: '0.8rem',
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    paddingRight: '1.6rem',
    paddingTop: '0',
  },
});

const link: Styles = (theme) => ({
  display:'none',
  [theme.breakpoints.up(mobileResponsiveBreakpointSm)]: {
    fontSize: '1.8rem',
    width: 'fit-content',
    display:'inline',
    textDecoration: 'underline',
  },
});

const headerListTypography: Styles = (theme) => ({
  textTransform: 'uppercase',
  alignSelf: 'center',
  [theme.breakpoints.up(mobileResponsiveBreakpointLg)]: {
    height: '4.6rem',
    lineHeight: '4.6rem',
  },
});

const styles = {
  logo,
  hamburgerIconWrapper,
  headerListWrapper,
  mobileDisplayWrapper,
  linkFirst,
  link,
  desktopDisplayWrapper,
  headerListTypography,
  headerWrapper,
};

export default styles;
