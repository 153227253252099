import {  Stack, Typography } from '@mui/material';
// import getEstimationDetails from 'utils/mocks/estimationDetails';
import { useTranslation } from 'react-i18next';
import ExtraContent from 'common/ExtraContent/ExtraContent';
import emailValidation from 'utils/validators/email';
import getHistoricParcels from 'utils/mocks/historicParcels';
import useGetEstimation from 'hooks/useGetEstimation';

import DetailEstimationBarChart from './DetailEstimationBarChart/DetailEstimationBarChart';
import styles from './styles';
import PollutionType from './enums';

interface DetailEstimationProps {
  isEmailSent: boolean;
  handleSendEmail: (text:string) => void;
}

export default function DetailEstimation({
  isEmailSent,
  handleSendEmail,
}: DetailEstimationProps) {

 

  const { impact, orderQuantity, parcelType  } = useGetEstimation();

  const { t } = useTranslation();

  const { historicParcels } = getHistoricParcels();

  let selectedParcel = { 
    brand: '',
    type : '',
    size: '',
    carbon : 0,
    waste : 0,
    water : 0
  }

  const h =  historicParcels.map((parcel) => {
    const b = { 
      brand: parcel.brand,
      type : parcel.type,
      size: parcel.size,
      carbon : Math.round(parcel.carbon * Number(orderQuantity)),
      waste : Math.round(parcel.waste * Number(orderQuantity)),
      water : Math.round(parcel.water * Number(orderQuantity))
    }

    if ( parcelType === parcel.type) {
      selectedParcel = b
    }

    return b
  })

  h.push( { 
    brand: 'generic',
    type : 'MOBIUSpack',
    size: 'one size',
    carbon : impact.carbonValue,
    waste : impact.wasteValue,
    water : impact.waterValue
  })

const co2Data = h
const wasteData = h
// const waterData = h
  
  const convertedC02Data = co2Data.map((d) => ({
    type: d.type as PollutionType,
    // @ts-ignore
    value: d.carbon,
    dimension: 'kg'
  }));

  const convertedWasteData = wasteData.map((d) => ({
    type: d.type as PollutionType,
    // @ts-ignore
    value: d.waste,
    dimension: 'kg'
  }));

  // const convertedWaterData = waterData.map((d) => ({
  //   type: d.type as PollutionType,
  //   // @ts-ignore
  //   value: d.water,
  //   dimension: 'l'
  // }));

  return (
    <Stack sx={styles.container}>
      <Typography variant="h3">{t('detailEstimation.title')}</Typography>

      <Typography sx={styles.headline}>{t('detailEstimation.description')} <div style ={{marginTop: '1.5rem'}}>{t('detailEstimation.description.contact')}</div></Typography>
      <ExtraContent
        isVisible={isEmailSent}
        onClick={handleSendEmail}
        buttonLabel={t('detailEstimation.discover_my_detail_estimation')}
        validation={emailValidation}
        placeHolder="email professionnnel"
      >
        <Stack
          sx={{
            ...styles.barChartSectionsWrapper,
            ...(!isEmailSent && { filter: 'blur(0.3rem)' }),
          }}
        >

          {/* <Typography sx={styles.headline}>{headlineText}</Typography> */}
          <Stack sx={styles.barChartSectionWrapper}>
            <Typography variant="h4" textTransform="uppercase">
              {t('detailEstimation.generated_co2_per_year')}
            </Typography>
            <Typography variant="subtitle2" sx={styles.barChartSectionSubtitle}>
              {/* {t('detailEstimation.generated_co2_per_year_subtitle')} */}
              {`Vous avez généré l'an dernier environ ${selectedParcel.carbon}kg de CO2 avec des emballages en ${parcelType}`}.
            </Typography>
            <Stack sx={styles.barChartWrapper}>
              <DetailEstimationBarChart
                data={convertedC02Data}
                type={PollutionType.co2}
              />
            </Stack>
            <Stack sx={styles.barChartSectionDescriptionWrapper}>
              <Typography
                variant="subtitle2"
                sx={styles.barChartSectionDescription}
              >
                {t('detailEstimation.generated_co2_per_year_description')}
              </Typography>
            </Stack>
          </Stack>

          {/* <Typography sx={styles.headline}>{headlineText}</Typography> */}
          <Stack sx={styles.barChartSectionWrapper}>
            <Typography variant="h4" textTransform="uppercase">
              {t('detailEstimation.generated_waste_per_year')}
            </Typography>
            <Typography variant="subtitle2" sx={styles.barChartSectionSubtitle}>
            {`Vous avez généré l'an dernier environ ${selectedParcel.waste}kg de déchets avec des emballages en ${parcelType}`}.
            </Typography>
            <Stack sx={styles.barChartWrapper}>
              <DetailEstimationBarChart
                data={convertedWasteData}
                type={PollutionType.waste}
              />
            </Stack>
            <Stack sx={styles.barChartSectionDescriptionWrapper}>
              <Typography
                variant="subtitle2"
                sx={styles.barChartSectionDescription}
              >
                {t('detailEstimation.generated_waste_per_year_description')}
              </Typography>
            </Stack>
          </Stack>


          {/* <Typography sx={styles.headline}>{headlineText}</Typography> */}
          {/* <Stack sx={styles.barChartSectionWrapper}>
            <Typography variant="h4" textTransform="uppercase">
              {t('detailEstimation.generated_water_per_year')}
            </Typography>
            <Typography variant="subtitle2" sx={styles.barChartSectionSubtitle}>
            {`Vous avez consomé l'an dernier environ ${selectedParcel.water} litres d'eau avec des emballages en ${parcelType}`}.
            </Typography>
            <Stack sx={styles.barChartWrapper}>
              <DetailEstimationBarChart
                data={convertedWaterData}
                type={PollutionType.water}
              />
            </Stack>
            <Stack sx={styles.barChartSectionDescriptionWrapper}>
              <Typography
                variant="subtitle2"
                sx={styles.barChartSectionDescription}
              >
                {t('detailEstimation.generated_water_per_year_description')}
              </Typography>
            </Stack>
          </Stack> */}

        </Stack>
      </ExtraContent>
    </Stack>
  );
}
