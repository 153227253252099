import { Styles } from 'theme/interfaces';

const responsiveBreakPointLg = 'lg';
const responsiveBreakPointMd = 'md';

const container: Styles = (theme) => ({
  marginTop: theme.spacing(2),
  marginX: theme.spacing(1),
  gap: theme.spacing(2),
  
  
  
  [theme.breakpoints.up(responsiveBreakPointLg)]: {
    marginTop: theme.spacing(3),
    flexDirection: 'row',
    maxWidth: '121.1rem',
    position: 'relative',
    marginX: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const rowContainer: Styles = (theme) => ({
 
  marginX: theme.spacing(1),
  gap: theme.spacing(1),
  
  [theme.breakpoints.up(responsiveBreakPointLg)]: {
    flexDirection: 'row',
    maxWidth: '121.1rem',
    position: 'relative',
    marginX: 0,
    left: '50%', /* 48.5% */
    transform: 'translateX(-50%)',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const defaultSideWrapper: Styles = (theme) => ({
  width: '100%',
  gap: '4rem',
  boxSizing: 'border-box',
  borderRadius: theme.spacing(0),
  border: '0.1rem solid #777777',
  [theme.breakpoints.up(responsiveBreakPointLg)]: {
    padding: '3.6rem',
  },
});

const defaultSubtitle: Styles = (theme) => ({
  [theme.breakpoints.up(responsiveBreakPointLg)]: {
    minHeight: '20.6rem',
  },
});

const mainSideWrapper: Styles = (theme) => ({
  width: '100%',
  gap: '4rem',
  boxSizing: 'border-box',
  padding: theme.spacing(0),
  borderRadius: theme.spacing(0),
  [theme.breakpoints.up(responsiveBreakPointLg)]: {
    padding: '3.6rem',
    gap: 0,
  },
});

const titlesWrapper: Styles = () => ({
  gap: '2.4rem',
  minHeight: '20.6rem',
  margin: '2.4rem',
});

const mainSubTitle: Styles = (theme) => ({
  [theme.breakpoints.down(responsiveBreakPointLg)]:{
    marginTop: '-4.7rem',
    fontSize: '1.7rem',       
    textAlign: 'justify',
  },
  [theme.breakpoints.up(responsiveBreakPointLg)]: {
    marginBottom: '1.5rem',
    fontSize: '2.5rem',
  },
});

const button: Styles = (theme) => ({
  [theme.breakpoints.up(responsiveBreakPointMd)]: {
    alignSelf: 'flex-end',
    maxWidth: '27rem',
  },
});



const styles = {
  container,
  defaultSubtitle,
  defaultSideWrapper,
  mainSideWrapper,
  titlesWrapper,
  mainSubTitle,
  button,
  rowContainer,
  
};

export default styles;
