import { Styles } from 'theme/interfaces';

import { CHART_BAR_MAX_WIDTH } from './dimensions';

const container: Styles = () => ({
  position: 'relative',
  width: '100%',
  maxWidth: `${CHART_BAR_MAX_WIDTH}px`,
  alignSelf: 'center',
});

const tooltip: Styles = (theme) => ({
  opacity: 0,
  top: 0,
  left: 0,
  position: 'absolute',
  pointerEvents: 'none',
  color: theme.palette.common.white,
  fontSize: '1rem',
  lineHeight: '2rem',
  fontWeight: 700,
  border: `0.1rem solid ${theme.palette.common.white}`,
  padding: '0.46rem',
  borderRadius: '0.46rem',
});

const styles = {
  container,
  tooltip,
};

export default styles;
