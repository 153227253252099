export enum BoxType {
  default = 'default',
  main = 'main',
}

export interface EstimationDetails {
  type: string;
  carbon: number;
  waste: number;
  water: number;
  score: number;
}


export interface EstimationDetailsV2 {
  name: string;
  value: number;
  
}


