// => endpoint GET : https://mobiuspack.io/v1/api/simulator/historic-parcels

export default function getHistoricParcels() {
  return {
    historicParcels: [
      {
        brand: 'generic',
        type: 'Carton 100% recyclé',
        size: 'one size',
        carbon: 0.41407,
        waste: 0.200,
        water: 1.45,
        score: 30.6
      },
      {
        brand: 'generic',
        type: 'Carton vierge',
        size: 'one size',
        carbon: 0.56807,
        waste: 0.200,
        water: 1.75,
        score: 47.9
      },
      {
        brand: 'generic',
        type: 'Polybag 60% recyclé',
        size: 'one size',
        carbon: 0.35975,
        waste: 0.053,
        water: 1.0,
        score: 32.89
      },
      {
        brand: 'generic',
        type: 'Kraft 50% recyclé',
        size: 'one size',
        carbon: 0.08731,
        waste: 0.068,
        water: 0.94,
        score: 7.35
      },
    ],
  };
}
