// import { useState } from 'react';
import { Stack, Box, /* IconButton, */Link } from '@mui/material';
// import Container from 'common/Container/Container';
import Navbar from 'common/Navbar/Navbar';
// import HamburgerIcon from 'assets/icons/HamburgerIcon';
import Logo from 'assets/images/Logo.png';
// import Collapse from 'common/Collapse/Collapse';

// import HeaderList from './HeaderList/HeaderList';
import styles from './styles';

export default function Header() {
  /* const [isNavListCollapsed, setIsNavListCollapsed] = useState(false);
  
  const toggleIsNavListCollapsed = () =>
    setIsNavListCollapsed(!isNavListCollapsed); */

  return (
    <Stack>
      <Navbar>
        <Stack direction="row" sx={styles.headerWrapper}>
          <Box sx={styles.logo} component="img" src={Logo} />
          <Link href="https://www.mobiuspack.com/" sx={styles.link}> 
          Retour vers mobiuspack.com
          </Link>
          { /*  /*Menu removal*\
          * <IconButton
            onClick={toggleIsNavListCollapsed}
            sx={styles.hamburgerIconWrapper}
          >
            <HamburgerIcon sx={{ fontSize: 30 }} />
            </IconButton> 
          <Box sx={styles.desktopDisplayWrapper}>
            <HeaderList />
          </Box>
        */}</Stack>
      </Navbar>
      
  { /* <Box sx={styles.mobileDisplayWrapper}>
        <Collapse isExpanded={isNavListCollapsed}>
          <HeaderList />
        </Collapse>
      </Box> */}
    </Stack>
  );
}
