import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGetEstimation from 'hooks/useGetEstimation';
// import { Fragment } from 'react';

import { BoxType, EstimationDetailsV2} from '../interfaces';

import styles from './styles';



/*  based on ScoreBoxes  but it's a component for one box only */

interface ScoreBoxV2Props {
  type: BoxType;
  estimation: EstimationDetailsV2;
  caption?: string;
  style?: React.CSSProperties;
 
}

export default function ScoreBoxV2({
  type,
  estimation,
  caption,
  style,
  
}: ScoreBoxV2Props) {
  const { t } = useTranslation();
  

  const { impact } = useGetEstimation();

  const percentageReduction = (impact as any)[`${estimation.name}PercentageReduction`];

  return (
    
      <Stack
        sx={
          type === BoxType.default
            ? styles.defaultBoxWrapper
            : styles.mainBoxWrapper
        }
        style ={style}
      >
        <Typography sx={styles.counter} variant="subtitle1">
          {/* <Typography sx={styles.prefix} variant="subtitle1"></Typography> */}
          {/* <Box component="span" m="{1}" sx={styles.prefix}> {estimation.name === 'parcel' ? "AVEC " : ""}</Box> */}
          {estimation.value}
        </Typography>
        <Typography sx={styles.unit} variant="subtitle1">
        {(estimation.value === 1 || estimation.value === 0) && estimation.name === 'parcel'
          ? <span>emballage<br/>réemployable</span>
          : t(`impactScore.${estimation.name}_unit`).split('\n').map((line:string) => (
              <div key={line}>
                {line}
                <br />
              </div>
            )
        )}
      </Typography>
        {/* If we have a percentageRedution or an other caption we display it */}
        <Typography sx={styles.description} variant="subtitle1">
            { percentageReduction? `Soit ${percentageReduction.toFixed(1)} %` : null }
        </Typography>
        {caption && <Typography sx={styles.def} variant="subtitle1">
            {caption}
        </Typography>}
      </Stack>
    
  );
}
