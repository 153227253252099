import { Styles } from 'theme/interfaces';
import colors from 'theme/colors';

const mobileResponsiveBreakpoint = 'md';

const container: Styles = (theme) => ({
  position: 'relative',
  width: '100%',
  height: '65rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    height: '50rem',
  },
});

const linearGradient: Styles = () => ({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `linear-gradient(180deg, rgba(30, 30, 30, 0.00) 0.83%, rgba(30, 30, 30, 0.95) 35.78%, ${colors.BLACK} 120.07%)`
});

const emailFormWrapper: Styles = (theme) => ({
  position: 'absolute',
  top: '30rem',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  width: '70%',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    flexDirection: 'row',
  },
});

const button: Styles = (theme) => ({
  height: '6.15rem',
  paddingTop: '1rem',
  paddingBottom:'1.15rem',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: '#d8ccc4', 
  [theme.breakpoints.down(mobileResponsiveBreakpoint)]: {
    height: '6.6rem',
    marginRight:'0.6rem',

  },
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    marginLeft: '0.6rem',
    width: 'calc(50% - 0.3rem)',
  },
});

const input: Styles = (theme) => ({
  width: '100%',
  marginTop:'2rem',
  marginBottom:'3rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    width: 'calc(50% - 0.3rem)',
    
  },
});

const styles = {
  container,
  emailFormWrapper,
  linearGradient,
  button,
  input,
};

export default styles;
