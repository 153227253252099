// => endpoint GET : https://mobiuspack.io/v1/api/simulator/config

export default function getConfig() {
  return {
    config: {
      orderQuantity: '100',
      replacementPercentage: '7',
      CO2Unit: 'kgCO2e',
      CO2Reference: 'MPLMV1',
      CO2Formula: 'C02ComputeV1',
      wastUnit: 'Tonne',
      wastReference: 'MPLMV1',
      wastFormula: 'wastComputeV1',
    },
  };
}
