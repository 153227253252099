import colors from 'theme/colors';

import {
  X_AXIS_TEXT_OFFSET,
  Y_AXIS_TICKS_LINE_OFFSET,
  Y_AXIS_TICKS_TEXT_OFFSET,
  mobileResponsiveBreakpointMd
} from './dimensions';

export const createGradient = (
  svg: d3.Selection<null, unknown, null, undefined>,
) => {
  const gradient = svg
    .append('defs')
    .append('linearGradient')
    .attr('id', 'myGradient')
    .attr('x1', '0%')
    .attr('y1', '0%')
    .attr('x2', '0%')
    .attr('y2', '100%');

  // Define the gradient stops based on the provided CSS linear-gradient
  gradient
    .append('stop')
    .attr('offset', '0%')
    .style('stop-color', colors.BLUE_BAR_CHART_GRADIENT_DARK);

  gradient
    .append('stop')
    .attr('offset', '100%')
    .style('stop-color', colors.BLUE_BAR_CHART_GRADIENT_LIGHT);
};

export const styleXAxis = (
  xAxisGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
) => {
  if (window.innerWidth < mobileResponsiveBreakpointMd) {
    xAxisGroup.selectAll('text').remove();
    xAxisGroup.selectAll('.tick')
    .append('foreignObject')
    .attr('transform', `translate(0,${X_AXIS_TEXT_OFFSET})`)
    .attr('y', -3)
    .attr('x', -27)
    .style('width', '5.9rem')
    .style('height', '20%')
    .append('xhtml:body') 
    .style('line-height', '1.5rem')
    .style('text-align', 'center')
    .style('font-size', '1.0rem')
    .style('font-family', 'SerialC')
    .style('color', colors.WHITE)
    .style('white-space', 'normal')
    .style('margin-left', '0.5rem')
    .style('margin-right', '0.5rem')
    .append('text')

    .attr('dx', '10rem')
    .attr('dy', '10rem') 
    .text((d: any) => d.toString());
} else {
  
  xAxisGroup
    .selectAll('text')
    .attr('transform', `translate(0,${X_AXIS_TEXT_OFFSET})`)
    .style('fill', colors.WHITE)
    .style('font-size', '1.2rem')
    .style('font-family', 'SerialC');
}

  xAxisGroup.selectAll('.domain').style('stroke', 'none');
  xAxisGroup.selectAll('.tick line').style('stroke', 'none');
};

export const styleYAxis = (
  yAxisGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
) => {
  yAxisGroup
    .selectAll('text')
    .style('fill', colors.GREY_BAR_CHART_FONT)
    .style('font-size', '1.2rem')
    .style('font-family', 'SerialC')
    .style('font-weight', '500')
    .attr('x', Y_AXIS_TICKS_TEXT_OFFSET);

  yAxisGroup.select('.domain').style('stroke', 'none');

  yAxisGroup
    .selectAll('.tick line')
    .style('stroke', colors.GREY_BAR_CHART_TICK)
    .attr('transform', `translate(${Y_AXIS_TICKS_LINE_OFFSET}, 0)`);
};
