import { useState } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';

import styles from './styles';

interface ExtraContentProps {
  children: React.ReactNode;
  isVisible: boolean;
  buttonLabel: string;
  onClick: (text:string) => void;
  validation: (text: string) => boolean;
  placeHolder?: string;
}

export default function ExtraContent({
  children,
  isVisible,
  onClick,
  buttonLabel,
  validation,
  placeHolder,
}: ExtraContentProps) {
  const [text, setText] = useState('');

  if (!isVisible) {
    return (
      <Box sx={styles.container}>
        <Box sx={styles.linearGradient}>
          <Box sx={styles.emailFormWrapper}>
            <TextField
              sx={styles.input}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder= {placeHolder}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && validation(text)) {
                  e.preventDefault(); 
                  onClick(text);
                }
              }}
            />
            <Button
              onClick={() => onClick(text)}
              sx={styles.button}
              disabled={!validation(text)}
            >
              <Typography variant="button" color="common.black">
                {buttonLabel}
              </Typography>
            </Button>
          </Box>
        </Box>
        {children}
      </Box>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
