import { Stack } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PromoTile1 from 'assets/images/promoTile1.png';
import PromoTile2 from 'assets/images/promoTile2.png';
import PromoTile3 from 'assets/images/promoTile3.png';
import PromoTile4 from 'assets/images/promoTile4.png';
import PromoTile5 from 'assets/images/promoTile5.png';
import PromoTile6 from 'assets/images/promoTile6.png';

import './override.css';
import styles from './styles';

const images = [
  PromoTile1,
  PromoTile2,
  PromoTile3,
  PromoTile4,
  PromoTile5,
  PromoTile6,
];

export default function PromoTiles() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    centerPadding: '0%',
    responsive: [
      {
        breakpoint: 1316,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 512,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
        },
      },
    ],
  };

  return (
    <Stack sx={styles.container}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <img
            key={image}
            src={image}
            alt={`Slide ${index + 1}`}
            style={{
              margin: 'auto',
              maxWidth: '100%',
              height: '100%',
            }}
          />
        ))}
      </Slider>
    </Stack>
  );
}
