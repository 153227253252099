import { Styles } from 'theme/interfaces';

const mobileResponsiveBreakpoint = 'md';

const container: Styles = (theme) => ({
  justifyContent: 'center',
  paddingX: theme.spacing(1),
  paddingTop: '5rem',
  alignItems: 'flex-start',
  gap: '3.0rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    maxWidth: '106.8rem',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

const button: Styles = (theme) => ({
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    alignSelf: 'flex-end',
    maxWidth: '27rem',
    paddingX:'2.4rem',
    
    '&:hover': {
      backgroundColor: '#d4d4d4',
    },
  },
});
const introduction: Styles = () => ({
  textAlign: 'justify',
  backgroundColor: '#d8ccc4',
  color: 'black',
  paddingX: '2.2rem',
  paddingY: '1.6rem',
  borderRadius: '1rem',
  fontSize: '1.5rem',
  lineHeight: '1.8',
});

const description: Styles = (theme) => ({
  textAlign: 'justify',
  fontSize: '2rem',
  
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    fontSize: '2.0rem',
    lineHeight: '3.5rem',
  },
  
  
});

const title: Styles = () => ({
  maxWidth: '48rem',
  
  
});

const styles = {
  container,
  button,
  introduction,
  description,
  title,
};

export default styles;
