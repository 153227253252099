import { Styles } from 'theme/interfaces';

const mobileResponsiveBreakpoint = 'md';

const container: Styles = (theme) => ({
  width: '100%',
  gap: theme.spacing(1),
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    flexDirection: 'row',
    gap: '3.2rem',
  },
});

const defaultBoxWrapper: Styles = (theme) => ({
  width: '100%',
  height: '23.8rem',
  gap: theme.spacing(0),
  border: `0.1rem solid ${theme.palette.common.white}`,
  borderRadius: '0.8rem',
  padding: theme.spacing(1),
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'center',
});

const mainBoxWrapper: Styles = (theme) => ({
  width: '100%',
  height: '23.8rem',
  
  border: `0.1rem solid ${theme.palette.common.white}`,
  borderRadius: '0.8rem',
  paddingBottom: '3rem',
  paddingLeft: '2rem',
  paddingRight:'2rem',
  paddingTop: '2rem',
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    height: '29rem',
    padding: theme.spacing(1),
  },
});

const counter: Styles = (theme) => ({
  fontFamily: 'Dharma-Gothic-Rounded-E',
  color: theme.palette.common.white,
  fontSize: '8rem',
  fontWeight: 400,
  lineHeight: '9.6rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    fontSize: '9.6rem',
  },
});

const unit: Styles = (theme) => ({
  fontFamily: 'Dharma-Gothic-Rounded-E',
  color: theme.palette.common.white,
  fontSize: '2.4rem',
  lineHeight: '100%',
  fontStyle: 'normal',
  fontWeight: 400,
  alignSelf: 'center',
  textAlign: 'center',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    fontSize: '3.2rem',
    lineHeight: '3.07rem',
  },
});

const def: Styles = (theme) => ({
  fontFamily: 'Dharma-Gothic-Rounded-E',
  color: theme.palette.common.white,
  fontSize: '2.4rem',
  lineHeight: '100%',
  fontStyle: 'normal',
  fontWeight: 300,
  textAlign: 'center',
  // textDecoration : 'underline',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    fontSize: '2.4rem',
    lineHeight: '3.07rem',
  },
});

const description: Styles = (theme) => ({
  fontFamily: 'Dharma-Gothic-Rounded-E',
  color: theme.palette.common.white,
  fontSize: '2.4rem',
  lineHeight: '100%',
  fontStyle: 'normal',
  fontWeight: 400,
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    fontSize: '3.2rem',
    lineHeight: '3.07rem',
  },
});

const line: Styles = () => ({
  fontFamily: 'Dharma-Gothic-Rounded-E',
  fontSize: '2.4rem',
  lineHeight: '100%',
  fontStyle: 'normal',
  fontWeight: 400,
  
});

const prefix: Styles = () => ({
  fontFamily: 'Dharma-Gothic-Rounded-E',
  fontSize: '2rem',
  lineHeight: '100%',
  fontStyle: 'normal',
  fontWeight: 300,
  
});


const styles = {
  container,
  defaultBoxWrapper,
  mainBoxWrapper,
  counter,
  unit,
  def,
  description,
  line,
  prefix,
};

export default styles;
