import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

export interface ResponseErrors {
  code: string;
  message: string;
  propertyPath: string;
}

export interface CustomError {
  status: number;
  data: {
    detail: string;
    title: string;
    type: string;
    violations: ResponseErrors[];
  };
}

export const api = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      headers.set(
        process.env.REACT_APP_AUTH_HEADER as string,
        process.env.REACT_APP_AUTH_TOKEN as string,
      );

      if (!headers.has('accept')) {
        headers.set('accept', 'application/json');
      }
      return headers;
    },
    paramsSerializer: (params) =>
      queryString.stringify(params, { arrayFormat: 'bracket' }),
  }) as BaseQueryFn<
    string | FetchArgs,
    unknown,
    CustomError,
    {},
    FetchBaseQueryMeta
  >,
  tagTypes: [],
  endpoints: () => ({}),
});

export default api;
