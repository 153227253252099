import { Stack, Typography, Button } from '@mui/material';
import { scroller as scroll } from 'react-scroll';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import { useTranslation } from 'react-i18next';
import useGetEstimation from 'hooks/useGetEstimation';
import getHistoricParcels from 'utils/mocks/historicParcels';
import { useState } from 'react';
import { ParcelType } from 'store/estimate/defaultState';

import CalculatorInputs from './CalculatorInputs/CalculatorInputs';
import styles from './styles';

export default function Calculator() {
  
  const [inputOrderQuantity, setInputOrderQuantity] = useState('100');
  const [inputParcelType, setInputParcelType] = useState<ParcelType>(ParcelType.carton);

  const { t } = useTranslation();

  const { historicParcels } = getHistoricParcels();

   const { /* parcelType, orderQuantity, */ setImpact  , setOrderQuantity, setParcelType  } = useGetEstimation();

  const computEcologicalImpact = ( type : string, OrderQuantity : any ) => {
    const defaultUsageValue = Number(1.8)
    const mobiusQuentity = Math.round( ((Number(OrderQuantity)/12) / defaultUsageValue)) + 1
  
    const mobiuspackFixedCarbon = Number(0.49436)
    const mobiuspackVarCarbon = Number(0.16030)
    const mobiuspackWaste = Number(0.005)
    const mobiuspackWater = Number(7.18)
    // const mobiuspackScore = Number(71.36)

    let selectedParcelCarbonValue = Number(0)
    let selectedParcelWaterValue = Number(0)
    let selectedParcelWasteValue = Number(0)

    historicParcels.map((parcel) => {
      if(parcel.type === type) {
        selectedParcelCarbonValue = parcel.carbon 
        selectedParcelWaterValue = parcel.water
        selectedParcelWasteValue = parcel.waste
      }
      return parcel 
    })

    const computedImpact = {
      carbonValue : (mobiusQuentity * ( mobiuspackFixedCarbon + mobiuspackVarCarbon * defaultUsageValue )),
      carbonReduction: Math.round(( (OrderQuantity * selectedParcelCarbonValue) - (mobiusQuentity * (mobiuspackFixedCarbon + mobiuspackVarCarbon * defaultUsageValue)) )),
      carbonPercentageReduction : ((mobiusQuentity * (mobiuspackFixedCarbon + mobiuspackVarCarbon * defaultUsageValue)) / (OrderQuantity * selectedParcelCarbonValue) - 1) * 100,
      wasteValue : (mobiusQuentity * mobiuspackWaste),
      wasteReduction: Math.round((OrderQuantity * selectedParcelWasteValue) - (mobiusQuentity * mobiuspackWaste)),
      wastePercentageReduction : ((mobiusQuentity * mobiuspackWaste) / (OrderQuantity * selectedParcelWasteValue) - 1) * 100,
      waterValue : (mobiusQuentity * mobiuspackWater),
      waterReduction: Math.round((OrderQuantity * selectedParcelWaterValue) - (mobiusQuentity * mobiuspackWater)),
      waterPercentageReduction : ((mobiusQuentity * mobiuspackWater) / (OrderQuantity * selectedParcelWaterValue)  - 1) * 100,
      scoreValue : 10,
      scoreReduction: 100,
      scorePercentageReduction : 60,
    }

    setImpact(computedImpact)

  }

  const handleSubmit = () => {
    computEcologicalImpact(inputParcelType, inputOrderQuantity)
    setParcelType(inputParcelType)
    setOrderQuantity(inputOrderQuantity)
    scroll.scrollTo('impact-score', {
      duration: 800,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <Stack sx={styles.container}>
      <Typography variant="h1" sx={styles.title}>{t('calculator.title')}</Typography>
      <Typography sx={styles.introduction}  >  
      {t('calculator.introduction1')}
      {t('calculator.introduction2')}
      <p>{t('calculator.introduction3')} <u>{t('calculator.introduction4')}</u></p>
      </Typography>
      <Typography variant="body1" sx={styles.description}>
      {t('calculator.description')}
      </Typography>
      <CalculatorInputs
      onOrderQuantityChange={setInputOrderQuantity} 
      onParcelTypeChange={setInputParcelType} 
      onClick={handleSubmit}
      inputOrderQuantity={inputOrderQuantity}
      inputParcelType={inputParcelType}
      />
      <Button onClick={handleSubmit} sx={styles.button}>
        <ArrowRightIcon />
        <Typography variant="button" color="common.black">
          {t('calculator.submit')}
        </Typography>
      </Button>
    </Stack>
  );
}
