const colors = {
  WHITE: '#FFFFFF',
  WHITE_HAMBURGER_ICON: '#FFFFFF8C',
  WHITE_HAMBURGER_BORDER: '#FFFFFF1A',
  WHITE_DISABLED: '#CCCCCC',
  BLACK: '#1E1E1E',
  BLUE_MAIN: '#2EA4C0',
  GREY_DISABLED: '#999999',
  GREY_BAR_CHART_TICK: '#FFFFFF33',
  GREY_BAR_CHART_FONT: '#969696',
  BLUE_BAR_CHART_GRADIENT_DARK: '#2EA9C7',
  BLUE_BAR_CHART_GRADIENT_LIGHT: '#2EA9C733',
};

export default colors;
