const links = [
  {
    label: 'footer.link_mentions',
    href: 'https://www.mobiuspack.com/mentions-legales/',
    target: '_parent',
  },
  {
    label: 'footer.link_linkedin',
    href: 'https://www.linkedin.com/company/mobiuspack/',
    target: '_blank',
  },
  {
    label: 'footer.link_instagram',
    href: 'https://www.instagram.com/mobiuspack/',
    target: '_blank',
  },
];

export default links;
