import { Stack, Typography, Button, Link } from '@mui/material';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import { useTranslation } from 'react-i18next';
import BackToBasicReverse from 'assets/images/back_to_basic_reverse.png';
import BackToBasic from 'assets/images/back_to_basic.png';


import styles from './styles';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <Stack sx={styles.container}>
      <Stack sx={styles.contactWrapperMobile}>
        <img
          src={BackToBasicReverse}
          alt="Back to basic"
          style={{
            width: '17.9rem',
            height: 'auto',
          }}
        />
        <Typography variant="subtitle2" sx={styles.title}>
          {t('contact.circular_revolution')}
        </Typography>
        <Button href="https://www.mobiuspack.com/#contact">
          <ArrowRightIcon />
          <Typography variant="button" color="common.black">
            {t('contact.read_manifesto')}
          </Typography>
        </Button>
      </Stack>
      <Stack sx={styles.contactWrapperDesktop}>
        <img
          src={BackToBasic}
          alt="Back to basic"
          style={{
            width: 'auto',
            height: '21.1rem',
            margin: '2.7rem 0',
          }}
        />
        <Typography variant="subtitle2" sx={styles.title}>
          {t('contact.solutions_for_your_logistics')}
        </Typography>
        <Stack>
          <Button
            href="https://www.mobiuspack.com/#contact"
            sx={styles.desktopButton}
          >
            <ArrowRightIcon />
            <Typography variant="button" color="common.black">
              <Link href= "https://calendly.com/mobiuspack/30min" target="_blank" rel="noopener noreferrer"  style={{ color: 'inherit' }}>
                {t('contact.contact_us')}
              </Link>
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
