import { Styles } from 'theme/interfaces';

const mobileResponsiveBreakpoint = 'lg';

const container: Styles = (theme) => ({
  width: '100%',
  gap: '2rem',
  // textAlign: 'right',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const inputWrapper: Styles = (theme) => ({
  gap: theme.spacing(0),
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
  },
});

const select: Styles = (theme) => ({
  marginRight: '10rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    width: '27rem',
  },
});

const input: Styles = (theme) => ({
  fontSize : '1.6rem',
  [theme.breakpoints.up(mobileResponsiveBreakpoint)]: {
    width: '27rem',
  },
});

const styles = {
  select,
  input,
  container,
  inputWrapper,
};

export default styles;
